window.addEventListener('load', footer)

function footer(){
  let programs = document.getElementById('programs-footer')
  let programItems = document.getElementById('program-items-footer')

  programs.addEventListener('click', togglePrograms)
  document.addEventListener('click', function(event){
    if(event.target.id !== 'programs-footer'){
      if(!(programItems.classList.contains('hide'))) programItems.classList.add('hide')
    }
  })


  function togglePrograms(){
    if(programItems.classList.contains('hide')){
      programItems.classList.remove('hide')
    }else if(!(programItems.classList.contains('hide'))){
      programItems.classList.add('hide')
    }
  }

}